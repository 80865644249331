import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/dermal-hero-pop.jpg";


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const TempleContentPage = ({setIsModalOpen}) => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-lg-5 pb-lg-5 pt-3 pb-3">

          <Row className="pb-4">
              <Col  data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-delay="300">
                <Iwrap>


<Itext as="h2">Temple Filler <span>UK</span></Itext>
</Iwrap>
      <SecondText>
      What is temple filler?

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className=""
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="300"
              data-aos-once="false"
            >
              <img src={imgMobile} alt="What is Temple Filler" className="rounded shadow" />
            </div>
          </Col>
          <Col data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="false"
            data-aos-delay="300" lg="6" md="9">
            <Box className=" pt-lg-0">

              <Text>

                Restore the natural contour and beauty of temples by reducing temple hollowness through dermal
                fillers. <br /> <br />

                The hollowing of temples is part of the natural ageing process. Through dermal filler injections, a
                natural and fuller temple can be achieved. <br /> <br />

                Temple fillers are injections that rejuvenate the temples without surgery, much like a mini-facelift. A
                hyaluronic acid (HA) based dermal filler is used to restore volume and moisture to the temporal
                region. <br /> <br />

                A substance that occurs naturally, hyaluronic acid aids in the water retention that keeps our skin
                hydrated and firm. Hyaluronic acid decreases over time but can be replaced by injecting it back into
                worn-out temple regions.<br /> <br />

              </Text>

              <Text><b>
                What causes Temple Hollowing?
              </b></Text> <br />
              <Text>
                Hollowing of the Temple is a noticeable sign of ageing which when treated has a significant impact on a
                patients face. <br /> <br />

                There are few factors that contribute to the formation/ worsening of temple hollowing, such as bone
                resorption due to temporalis use when chewing, combined with muscular atrophy and fat pad resorption.
                Over time, our skin also loses collagen, which is needed to keep the skin firm and soft. Other factors
                include weight loss and illness. <br />

              </Text>
              <br />
              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active"
              >
                <Bookbutton>
                  Book Appointment
                </Bookbutton>
              </div>

            </Box>
          </Col>
        </Row>


      </Container>
    </Section>
  </>
);

export default TempleContentPage;
