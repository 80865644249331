import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

 
// Demo styles, see 'Styles' section below for some notes on use.

import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';



const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

export default function Templefaq({setIsModalOpen}) {
    return (

      <>
      <Separator />
    
        <Section py={4} id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
            <Row className="justify-content-center pb-5">
              <Col data-aos="fade-up"
                   data-aos-duration="750"
                   data-aos-once="false"
                   data-aos-delay="300" lg="12">

                <Iwrap>


                  <Itext as="h2">More about <span>Temple Filler</span></Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row data-aos="fade-up"
                 data-aos-duration="750"
                 data-aos-once="false"
                 data-aos-delay="300" className="">
              <Col

              >
                <Accordion allowZeroExpanded>
                  <AccordionItem className='blanking'>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What does the Temple Filler involve?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Temple Filler treatment is a hyaluronic based dermal filler treatment London. <br /> <br />

                        Through a very fine needle, dermal filler is strategically injected in small amounts into
                        precise points of the temporal region. Fanning the filler through the temple area using canula
                        is also provided. The filler settles where it is injected to hold its shape, instantly modifying
                        the look of the temples. Anaesthetic cream is applied before the treatment begins to ensure
                        maximum comfort.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion> <br />


                <Accordion allowZeroExpanded className="">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long does the Temporal Filler take?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Temple Filler treatment takes no longer than 15 minutes. Patients will see immediate results
                        right after the treatment. No recovery time is needed and you can get back to your daily
                        activities or work the very same day.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>

                <br />


                <Accordion allowZeroExpanded className="">

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does Temple Filler hurt?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>

                        Numbing cream can be applied before the treatment begins to ensure maximum comfort. Only when
                        the numbing cream has taken its full effect do we begin the treatment. Based on our previous
                        patients, many do not feel pain, others feel mild discomfort.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>


                <br />


                <Accordion allowZeroExpanded className="">

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is Temple Filler safe?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Dermal fillers based on hyaluronic acid are not animal-based; they are generated naturally. It
                        is developed after significant laboratory research and is regarded as one of nature's most
                        versatile substances. <br /> <br />

                        As the body is able to break down the filler, over time the fillers dissolve naturally in the
                        body and the effects of the treatment progressively wear off. Similar to the hyaluronic acid
                        that naturally occurs in the skin, the filler degrades and is reabsorbed by the body.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>

                <br />

                <Accordion allowZeroExpanded className="">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is Temporal Filler permanent?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        One of the great advantages of having a filler treatment is that it does not permanently alter
                        your face, making it ideal for someone wanting to try out changes without committing to surgery
                        and a permanent change. <br /> <br />

                        Filler treatments can help you maintain your look as your face changes over time. You can
                        maintain your look by repeating the treatment over time and achieve the most natural look.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
                <br />
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How much is Temple Filler UK? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        At Dermamina London, the price of the Temple Filler starts at £450 for 1ml
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
                <br />


                <Accordion allowZeroExpanded className="">

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is a consultation provided on the day of the treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In-depth consultations do start off every appointment. At Dermamina London, pre-treatment
                        consultations are crucial in ensuring that patients are well-prepared and at ease in the hands
                        of their practitioners, as well as, most significantly, in order to understand their
                        expectations. In order to provide the best possible outcome, we want the patient to be as
                        involved as they would like and to offer input at every stage of the procedure.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion>

                <br />
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Am I suitable for the Temple Filler treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        At Dermamina, we try to enhance rather than entirely modify your appearance, focusing on
                        natural-looking outcomes that are personalised to individual suitability. <br /> <br />

                        If you are unsure whether the treatment will help you get the results you want, we advise
                        scheduling a consultation. It is crucial to discuss your expectations with the practitioner
                        during the session in order to determine whether or not they are realistic.<br /> <br />

                        Alternatively, you can also get in touch with a team member and explain your concern, and the
                        results you're hoping to attain.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>


              </Col>


            </Row>


            <br />
            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
            >
              <Bookbutton>
                Book Appointment
              </Bookbutton>
            </div>


          </Container>
        </Section>

        <SeparatorEnd />
      </>


    );
}